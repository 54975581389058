import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 0.5)
scene.add(ambientLight)

const pointLight = new THREE.PointLight(0xffffff, 0.5)
pointLight.position.x = 2
pointLight.position.y = 3
pointLight.position.z = 4
scene.add(pointLight)

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()

// const cubeColorTexture = textureLoader.load('/textures/lumo4/cube1.jpg')

// const gradientTexture = textureLoader.load('/textures/gradients/3.jpg')

// Material
const material = new THREE.MeshNormalMaterial()
material.wireframe = true

// const material = new THREE.MeshStandardMaterial()

// material.map = cubeColorTexture

// Object
const geometry = new THREE.TorusBufferGeometry(0.3, 0.18, 32, 64)
// const material = new THREE.MeshBasicMaterial({
//     // color: 0xff0000,
//     color: 0xFFF,
//     // color: 0x2e6f95,
//     // color: 0x143a44,
//     wireframe: true
// })
const mesh = new THREE.Mesh(geometry, material)
scene.add(mesh)

// Sizes
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

// Camera
const camera = new THREE.PerspectiveCamera(-1.25, sizes.width / sizes.height, 0.1, 1333)
camera.position.z = -45
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

// Renderer
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

// Animate
const clock = new THREE.Clock()

const tick = () => {
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    mesh.rotation.y = 0.1 * elapsedTime;
    mesh.rotation.x = 0.15 * elapsedTime;

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()